<template>
  <div class="main-layout">
    <b-navbar shadow class="main-layout-child">
      <template #brand>
        <b-navbar-item class="navbar-logo">
          <img src="../assets/DD_rgb.svg" />
        </b-navbar-item>
      </template>
      <template #start>
        <!-- <b-navbar-item v-if="role === '1'">
           <router-link class="navlink" to="/admin/documents">Документооборот</router-link>
        </b-navbar-item> -->
        <b-navbar-item v-if="role === '2'">
          <router-link class="navlink" to="/gis/editfields">Оцифровка полей</router-link>
        </b-navbar-item>
        <!-- <b-navbar-item v-if="role === '2'">
          <router-link class="navlink" to="/gis/fields">Результаты оцифровки</router-link>
        </b-navbar-item> -->
        <b-navbar-item v-if="role === '3'">
          <router-link class="navlink" to="/client/fields">Результаты оцифровки</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="role === '3'">
          <router-link class="navlink" to="/client/orders">Анализ почвы</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="role === '3'">
           <router-link class="navlink" to="/client/analyzes">Результаты анализа</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="role === '4'">
          <router-link class="navlink" to="/lab/orders">Лаборант</router-link>
        </b-navbar-item>
        <!-- //доступ для лаборанта -->
        <b-navbar-item v-if="role === '4'">
          <router-link class="navlink" to="/gis/fields">Результаты оцифровки</router-link>
        </b-navbar-item>
        <b-navbar-item v-if="role === '4'">
           <router-link class="navlink" to="/client/analyzes">Результаты анализа</router-link>
        </b-navbar-item>
        <!-- //====================== -->
        <b-navbar-item v-if="role === '1'">
          <router-link class="navlink" to="/admin/companies">Компании</router-link>
        </b-navbar-item>
         <b-navbar-item v-if="role === '1'" active>
          <router-link class="navlink" to="/admin/users">Пользователи</router-link>
        </b-navbar-item>
        <!-- <b-navbar-item v-if="role === '1'"> -->
          <!-- <router-link class="navlink" to="/admin/map-test">Тест карты</router-link> -->
        <!-- </b-navbar-item> -->
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <button v-if="role === '3'" @click="EditModal =! EditModal" class="button is-normal is-info">Контакты</button>
            <a class="button is-primary">
              <b-image
                class="profile-img"
                :src="require('@/assets/dd_profile_logo.png')"
                rounded
              ></b-image>
              <strong>{{user.first_name}} {{user.last_name}}</strong>
            </a>
            <a class="button is-danger" @click="logoutAttempt"> Выйти </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container1">
      <template v-if="EditModal">
        <div class="modal is-active">
          <div class="modal-background" @click="EditModal = false"></div>
          <div class="modal-content modal-content-box has-background-white is-flex is-flex-direction-column px-4 py-4">
            <p class="title">Контактная информация</p>
            <div class="my-2">Товарищество с ограниченной ответственностью «Агрохимическая компания ДАРКАН ДАЛА»</div>
            <div class="my-2">010000, Республика Казахстан, г.Нур-Султан, Есильский район, улица.Кайым Мухамедханов, здание 5.</div>
            <p>Тел. <a href="8 (7172) 999365">8 (7172) 999365</a> , 999366, 999367</p>

            <div class="my-2"><a href="mailto:info@darkandala.kz">info@darkandala.kz</a></div>
            <div class="my-2">
              Филиал «Исследовательский центр»  ТОО «Агрохимическая компания ДАРКАН ДАЛА» в городе Костанай
              г.Костанай, проспект Н.Назарбаева, 170
            </div>
            <a href="mailto:lab@darkandala.kz">lab@darkandala.kz</a>
            <a href="tel:8 (7142) 21 22 52"> 8 (7142) 21 22 52</a>
          </div>
          <button @click="EditModal = false" class="modal-close is-large" aria-label="close"></button>
        </div>
      </template>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: null,
        password: null,
        first_name: localStorage.user_first_name,
        last_name: localStorage.user_last_name,
      },
      route:null,
      role: null,
      message: null,
      EditModal:false
    };
  },

  beforeMount() {
    this.checkRole();
  },
  methods: {
    checkRole() {
      this.role = localStorage.role;
      this.route = this.$router.history.current.path;
    },
    logoutAttempt() {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('user_first_name');
      localStorage.removeItem('user_last_name');
      localStorage.removeItem('company_id');
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.main-layout{
 display: flex;
 flex-direction: column;
 /* overflow: hidden; */
}
.navbar-logo img {
  max-height: 55px;
  padding: 0 20px 0;
}
.profile-img {
  padding-right: 15px;
}
.button {
  max-width: 200px;
}
.main-layout-child{
  align-self: stretch;
}
.container1 {
  align-self:stretch;
  padding:0;
  width: 100%!important;
}
.navlink{
  font-size: 18px;
  color: #222B4D;
  font-weight: 500;
}
.modal{
  z-index: 100001 !important;
}

.modal-content-box{
  border-radius: 20px;
  width: 500px !important;
}

.navlink:hover {
  color: #61a937;
}
.navlink.router-link-exact-active.router-link-active{
  border-bottom: 1px solid #61a937;
}

@media print{
  @page {
    size: A4 landscape;
  }
  body {
    margin: 0;
    background: #CCCCCC;
  }
  div.landscape {
    width: 276mm;
    height: 190mm;
  }
  .sidebar, .navbar{
    display: none;
  }
}
</style>
